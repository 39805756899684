.NavbarItems {
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  padding: 2px 30px;
  background-color: #222 !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 80px;
  z-index: 999;
}

.logoname {
  color: #336699;
}

.nav-menu {
  display: flex;
  list-style: none;
  align-items: center;
  justify-content: flex-end;
}
 
 

.nav-link.logout {
  border: 1px solid;
  background: #357963;
  padding-left: 25px;
}

/* Bootstrap Color Schemes */

.NavbarItems {
  background-color: #343a40;
}
 
 

.nav-link.logout {
  background: #198754;
  margin-left: 9px;
  background: #198754;
}
 

/* Adjust layout for small screens */
@media (max-width: 1200px) {
  .NavbarItems {
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 10px;
    height: auto;
  }

  .logstyle {
    margin: 10px 0;
  }

  .nav-menu {
    flex-basis: 100%;
    justify-content: center;
    margin-top: 10px;
  }

  .nav-link.logout {
    margin-top: 10px;
  }
}

#others-dropdown {
  color: white !important;
  font-size: 13px !important;
  margin-left: 10px;
}

#others-dropdown:hover {
  background-color: #43a39f !important;
}
.dropdown-item {
  background-color: #1e1f21 !important;
}
.dropdown-menu.show {
  background-color: #1e1f21 !important;
}
.otherdropdown .dropdown-menu {
  background: #1e1f21 !important;
}

.list-items {
  width: 100%;
  color: white;
  padding: 6px 14px;
  text-decoration: none;
  font-size: 12px;
}

.list-items:hover {
  background: #008000;
  color: white;
}
