.form{
    background-color: #eee;
    margin: auto !important;
    margin-top: 60px !important;
    padding: 40px !important; 
    border-radius: 39px !important;
    box-shadow: 5px 11px 11px 5px rgba(0, 0, 0, 0.3) !important;
}


label{
    color: #000 !important;
}
.button2{
    background-color: #43a39f !important;
    color: white !important;
    border: none !important; 
    width: 100%;
}

.button2:hover{
    background-color: white !important;
    color: #43a39f !important;
    font-weight: 700 !important;
}

.button:hover{
    background-color: white !important;
    color: #43a39f !important;
    font-weight: 700 !important;
} 
.form label{
    top:-5px !important;
    color:#000 !important;
    opacity: 0.5;
font-weight: 100 !important;
}