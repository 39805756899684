.deleteStyle {
  border-radius: 50px !important;
  height: 25px;
  width: 25px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  border: 1px solid #43a39f;
}
.addStyle {
  border-radius: 50px !important;
  height: 26px;
  width: 26px;
  font-size: 14px;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  background-color: #43a39f !important;
  border-color: currentColor !important;
  color: #fff !important;
}
.btn.addStyle:hover {
  background-color: #31807d;
  color: #fff;
}

.btn.deleteStyle {
  padding-top: 0;
}
.deleteStyle span {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  color: #43a39f;
  transform: translate(0px, 2px);
}
.btn.deleteStyle:hover {
  background-color: #43a39f !important;
}
.btn.deleteStyle:hover span {
  color: #fff;
}
.deleteBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: white;
}

.txtbold {
  font-weight: bold !important;
  font-size: 12px;
}
.visible {
  display: inline-block !important;
}
.courselanding {
  box-shadow: 0px 2px 15px -3px;
  padding: 30px;
}
.mandatory {
  color: red;
}
.courselanding {
  padding: 20px;
}
.scheduledemo textarea {
  height: auto !important;
}
