.pageSubHeading {
  font-family: "Unbounded", sans-serif;
}

.formMain .addFormHeading {
  font-size: 28px;
  font-family: "Unbounded", sans-serif;
  color: #444444;
  font-weight: 700;
}
.formMain h5 {
  color: #e0a721;
  font-size: 16px;
  font-weight: 400;
}
.demosAddForm {
  background: #fff !important;
  transition: all 0.5s ease;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.1);
  border-radius: 20px !important;
  padding: 20px 24px !important;
  max-width: 920px;
  width: 100%;
  margin: 40px auto;
}

.bannerBTn {
  padding: 10px;
}
.imagePreview {
  font-size: 14px;
}

.demosAddBtn {
  font-size: 14px;
  font-weight: 500;
  border: 2px solid transparent !important;
  background-color: #43a39f !important;
  color: #fff;
  margin-right: 12px;
  text-align: center;
  border-radius: 4px;
  transition: all 0.35s;
}
.demosAddBtn:hover {
  border: 2px solid #43a39f;
  background-color: #fff;
  color: #43a39f;
}

.italicInput {
  font-style: italic;
}
.dynamicField {
  margin-top: -16px !important;
}
form .accordion-button:not(.collapsed) {
  background-color: transparent;
  border-radius: 0;
}

/* media query */
@media screen and (max-width: 786.98px) {
  .demosAddFormSection .addForm {
    padding: 0 !important;
  }
  .demosAddForm {
    max-width: 100% !important;
    padding: 20px !important;
  }
  .demosAddFormSection h2 {
    font-size: 24px !important;
    margin: 10px 0px 10px 0 !important;
  }
  .demosViewMoreSection .tab-content .tab-pane .card {
    margin-left: 0 !important;
    margin-left: 0 !important;
  }
  .demosViewMoreSection h5.theme-text-2 {
    font-size: 16px;
    line-height: 1.4;
  }
}

@media screen and (max-width: 575.98px) {
  .demosAddFormSection h2 {
    font-size: 18px !important;
    margin: 10px 0px 10px 0 !important;
  }
  .form-section-header h3 {
    font-size: 21px;
    font-weight: 600;
  }

  .demosAddFormSection p {
    font-size: 12px !important;
  }
  .demosAddBtn {
    padding: 6px;
    font-size: 14px;
  }
  .demosViewMoreSection .nav-tabs {
    overflow-x: auto;
    flex-wrap: nowrap;
  }
  .demosViewMoreSection h5.theme-text-2 {
    font-size: 14px;
    line-height: 1.4;
  }
}
