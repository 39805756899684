.socialicon *{
    margin: 0;
    padding: 0;
    list-style: none;
    text-decoration: none;
    box-sizing: border-box;
  }
  
  .socialicon nav{
    position: fixed;
    width: 70px;
    margin-top: 50px;
    transition: all 0.3s linear;
    box-shadow: 2px 2px 8px 0px rgba(0,0,0,.4);
    top:0;
    right: 0;
  }
  .socialicon nav li{
    height: 60px;
    position:relative;
    transition: all 0.3s linear;
  }
  .socialicon nav li a{
    color: white;
    display: block;
    height: 100%;
    width: 100%;
    line-height: 60px;
    padding-left:25%;
    border-bottom: 1px solid rgba(0,0,0,.4);
    transition: all .3s linear;
  }
  .socialicon nav li:nth-child(1) a{
    background: #4267B2;
  }
  .socialicon nav li:nth-child(2) a{
    background: #1DA1F2;
  }
  .socialicon nav li:nth-child(3) a{
    background: #E1306C;
  }
  .socialicon nav li:nth-child(4) a{
    background: #2867B2;
  }
  .socialicon nav li:nth-child(5) a{
    background: #333;
  }
  .socialicon nav li:nth-child(6) a{
    background: #ff0000;
  }
  .socialicon nav li a i{
    position:absolute;
    top: 17px;
    left: 20px;
    font-size: 27px;
  }
  .socialicon ul li a span{
    display: none;
    font-weight: bold;
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  .socialicon a:hover {
    z-index:1;
    width: 200px;    
   
}
.socialicon ul li:hover a span{
    padding-left: 10%;
    display: block;
  }
  .socialicon ul li:hover{
    right: 120px !important;
    width: 200px;    
  }