.profile-edit .images-upload {
  margin-bottom: 4rem;
}
.profile-edit .images-upload .imgBanner .bannerBTn.add-button {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #000;
  color: #fff;
}

.profile-edit .images-upload .imgBanner .imagePreview {
  display: none;
}

.profile-edit .images-upload .profile {
  position: absolute !important;
  left: 1.5rem;
  bottom: -4rem;
}

@media (max-width: 575.98px) {
  .profile-edit .images-upload .imgBanner .bannerBTn.add-button {
    top: 0;
    bottom: auto;
    font-size: 10px !important;
  }
  .profile-edit .images-upload .profile {
    left: 50%;
    transform: translateX(-50%);
  }
}
