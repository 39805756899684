.demosViewMoreSection {
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.1);
  border-radius: 16px;
  max-width: 980px;
  width: 100%;
}
.demosViewMoreSection .coverImg {
  width: 100%;
  padding-bottom: 9.77%;
  position: relative;
  overflow: hidden;
}
.demosViewMoreSection .coverImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px 10px 0 0;
}
.demosViewMoreSection .profileHeader {
  border: 3px solid #eee;
  border-radius: 10px 10px 0 0;
}
.demosViewMoreSection .profileHeader .profileImg {
  background-color: #fff;
  position: absolute;
  top: -80px;
  left: 25px;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  display: block;
  object-fit: contain;
  border: 4px solid #eee;
}
.demosViewMoreSection .profileHeader .trainerName {
  font-family: "Unbounded", sans-serif;
  color: #43a39f;
  text-decoration: none;
  font-size: 24px;
  text-transform: capitalize;
  line-height: 1;
}

.demosViewMoreSection .profileHeader .industry {
  font-size: 14px;
  font-weight: 500;
  color: #e0a721;
}

.demosViewMoreSection .profileHeader .social a {
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #222;
  color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.demosViewMoreSection .profileHeader .social a img {
  height: 20px;
}
.demosViewMoreSection .profileHeader .social a svg {
  transition: all 0.25s;
  font-size: 18px;
}
.demosViewMoreSection .profileHeader .social a:hover {
  scale: 1.1;
  border: 1px solid #e0a721;
  color: #e0a721;
}

.demosViewMoreSection .card-text br:last-of-type {
  display: none;
}
.demosViewMoreSection .card-text button {
  font-size: inherit;
}

.star {
  color: #e0a721;
}
.demosViewMoreSection .stars p {
  font-size: 13px;
}
.demosViewMoreSection .nav-tabs .nav-link {
  color: #000;
}
.demosViewMoreSection .nav-tabs .nav-link {
  font-weight: 500;
}
.demosViewMoreSection .nav-tabs .nav-link:focus,
.demosViewMoreSection .nav-tabs .nav-link:focus-visible,
.demosViewMoreSection .nav-tabs .nav-link.active {
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.05) !important;
  color: #43a39f;
}
.demosViewMoreSection .card p {
  font-size: 14px;
}
.demosViewMoreSection .detailReviewCard.card {
  border-radius: 6px;
  padding: 8px 8px 16px 8px;
  border: none;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.2);
}

.reviews-cont h4 {
  font-size: 18px;
  color: #222;
}
.reviews-cont p.h6 {
  font-size: 16px;
  color: #222;
}
.reviews-cont p {
  font-size: 14px;
  color: #555;
}

.lg-container.lg-show.lg-show-in {
  z-index: 99999;
  position: relative;
}

@media screen and (max-width: 767.98px) {
  .demosViewMoreSection .card,
  .demosViewMoreSection .profileHeader.mx-4 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }

  .demosViewMoreSection .profileHeader .trainerName {
    font-size: 22px;
  }
  .demosViewMoreSection .card .card-body {
    padding: 14px;
  }
  .demosViewMoreSection .card .card-body h5 {
    font-size: 18px;
  }
  .demosViewMoreSection .card .card-body hr {
    margin: 0.6rem 0;
  }
}
@media screen and (max-width: 575.98px) {
  .demosViewMoreSection .profileHeader .trainerName {
    text-align: center;
    font-size: 20px;
  }
  .demosViewMoreSection .card .card-body {
    padding: 12px;
  }
  .demosViewMoreSection .card .card-body h5 {
    font-size: 16px;
  }
  .demosViewMoreSection .card .card-body hr {
    margin: 0.5rem 0;
  }
  .demosViewMoreSection .profileHeader .profileImg {
    background-color: #fff;
    top: -60px;
    left: 50%;
    height: 120px;
    width: 120px;
    transform: translate(-50%, 0%);
  }
  .demosViewMoreSection .profileHeader .social a {
    width: 30px;
    height: 30px;
  }
  .demosViewMoreSection .profileHeader .social a img {
    height: 18px;
  }
  .demosViewMoreSection .profileHeader .social a svg {
    font-size: 16px;
  }
}
