.listUploadModal .addStyle.bg-danger {
  display: none !important;
}
.actionDltBtn {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  background-color: #e02128;
  color: #fff;
  border: 2px solid transparent;
  transition: all 0.35s;
}
.actionDltBtn:hover {
  border: 2px solid #e02128;
  color: #e02128;
  background-color: transparent;
}
.striped > div:nth-of-type(2n + 1) {
  border-top: 1px solid #35797544;
  border-bottom: 1px solid #35797544;
}
.striped > tbody > tr td {
  background-color: transparent;
}
.striped > tbody > tr:hover,
.striped > tbody > tr:nth-of-type(2n) {
  background-color: #35797512;
}
.striped > div:nth-of-type(2n) {
  background-color: #35797512;
}
.striped > div:last-child {
  border-bottom: none;
}

@media screen and (max-width: 767.98px) {
  .campaigns-wrapper .striped {
    width: 100%;
  }
  .campaigns-wrapper .md-border {
    border-bottom: 1px solid #43a39f;
  }
  .campaigns-wrapper .nav-pills .nav-item {
    flex-grow: 1;
    text-align: center;
  }
  .campaigns-wrapper .striped .list-name {
    min-width: 200px;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
  .campaigns-wrapper table.striped {
    width: 680px;
    overflow-x: scroll;
  }
}
@media screen and (max-width: 575.98px) {
  .actionDltBtn {
    font-size: 12px;
  }
  .campaigns-wrapper table.striped {
    width: 540px;
  }
  .campaigns-wrapper .striped .list-name {
    min-width: 160px;
  }
}
