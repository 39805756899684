.DemoCard.card {
  position: relative;
  border: 1px solid rgba(231, 226, 226, 0.437);
  border: none !important;
  border-radius: 6px;
  box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.15);
  min-height: 100%;
}

.DemoCard.card .img-bg {
  padding: 0;
  border: 1px solid #00848da7;
  border-radius: 6px;
  overflow: hidden;
  transition: all 0.25s ease;
}
.DemoCard.card:hover .img-bg {
  border: 1px solid #00848d00;
  transition: all 0.25s ease;
}
.DemoCard.card .img-bg img {
  height: 180px;
  width: 100%;
  object-fit: contain;
  border-radius: 6px;
  transform: none;
  will-change: transform;
  transition: all 0.25s ease;
}
.DemoCard.card:hover .img-bg img {
  transform: scale(1.1);
  transition: all 0.25s ease;
}
.DemoCard.card .card-body {
  margin: 16px auto 8px auto;
  text-align: center;
}
.DemoCard.card .card-title a {
  font-size: 20px;
  color: inherit;
  text-transform: capitalize;
  margin-bottom: 12px;
  text-decoration: none;
}
.DemoCard.card .card-text p {
  margin-bottom: 8px;
}
.DemoCard.card .card-body .btn-more.btn {
  position: relative;
  padding: 12px 22px;
  font-size: 14px;
  display: inline-block;
  background-color: transparent;
  color: #333;
  transition: all 0.25s ease;
  border: 1px solid transparent;
}
.DemoCard.card .card-body .btn-more.btn::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: calc(100% - 44px);
  height: 80%;
  border: 2px solid transparent;
  border-bottom-color: #333;
  transform: translate3d(-50%, -50%, 0);
  transition: width 0.25s ease, height 0.25s ease;
}
.card:hover .card-body .btn-more.btn::after {
  color: #42a49f;
  border: 2px solid #42a49f;
  height: 100%;
  width: 100%;
  transition: border-color 0.3s ease-in-out, width 0.25s ease, height 0.25s ease;
}

@media screen and (max-width: 1199.98px) {
  .DemoCard.card .card-title a {
    font-size: 17px;
  }
  .DemoCard.card .card-body {
    font-size: 14px;
  }
  .DemoCard.card .card-body .btn-more.btn {
    padding: 8px 20px;
  }
}

@media screen and (max-width: 767.98px) {
  .demosSection.section {
    padding-bottom: 12px !important;
  }

  .DemoCard.card .card-body {
    margin: 12px auto 6px auto;
  }

  .DemoCard.card .card-title {
    font-size: 22px;
    margin-bottom: 8px;
  }
  .DemoCard.card .card-text {
    width: 90% !important;
  }
  .DemoCard.card .card-text p {
    font-size: 16px;
    margin-bottom: 18px;
  }
  .DemoCard.card .card-body .btn-more.btn {
    width: fit-content;
    padding: 10px 22px;
  }
}
