.FAQs .accordion-button {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.FAQs .accordion-button.collapsed {
  border-radius: 2px !important;
  border: 1px solid #bbb !important;
  color: #333;
}
.FAQs .accordion-button:not(.collapsed) {
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  background-color: #42a49f;
  color: #fff;
  border-radius: 2px;
}
.FAQs .accordion-button::after {
  content: none;
}
.FAQs .accordion-button span {
  position: relative;
}
.FAQs .accordion-button span::after,
.FAQs .accordion-button span::before {
  content: "";
  width: 18px;
  height: 2px;
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.25s;
}
.FAQs .accordion-button.collapsed span::after {
  background-color: #666;
}
.FAQs .accordion-button.collapsed span::before {
  background-color: #666;
  transform: translateY(-50%) rotate(90deg);
}
.FAQs .accordion-button:not(.collapsed) span::after {
  background-color: #fff;
}
.FAQs .accordion-button:not(.collapsed) span::before {
  transform: translateY(-50%) rotate(0);
  background-color: #fff;
}

.FAQs .accordion-body {
  /* margin: 8px 0; */
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
  border: 1px solid #eee;
  border-radius: 2px !important;
}
@media screen and (max-width: 767.98px) {
  .FAQs .accordion-button {
    font-size: 14px;
    padding: 0.75rem;
  }
  .FAQs .accordion-body {
    font-size: 13px;
    padding: 0.5rem 0.75rem;
  }

  .FAQs .accordion-button span::after,
  .FAQs .accordion-button span::before {
    width: 16px;
    height: 1.5px;
  }
}
@media screen and (max-width: 575.98px) {
  .FAQs .accordion-button {
    align-items: start;
    font-size: 12px;
    line-height: 1.4;
  }
  .FAQs .accordion-body {
    font-size: 11px;
    padding: 0.5rem 0.75rem;
  }

  .FAQs .accordion-button span::after,
  .FAQs .accordion-button span::before {
    top: 0.5rem;
    width: 12px;
    height: 1px;
  }
}
