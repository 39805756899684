.trainerProfile {
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.1);
  border-radius: 16px;
  max-width: 920px;
  width: 100%;
}
.demosViewMoreSection .coverImg {
  width: 100%;
  padding-bottom: 9.77%;
  position: relative;
  overflow: hidden;
}
.demosViewMoreSection .coverImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 10px 10px 0 0;
}
.trainerProfile .profileHeader {
  border: 3px solid #eee;
  border-radius: 10px 10px 0 0;
}
.trainerProfile .profileHeader .profileImg {
  background-color: #fff;
  position: absolute;
  top: -80px;
  left: 25px;
  border-radius: 50%;
  height: 140px;
  width: 140px;
  display: block;
  object-fit: cover;
  border: 4px solid #eee;
}
.trainerProfile .profileHeader .trainerName {
  font-family: "Unbounded", sans-serif;
  color: #43a39f;
  text-decoration: none;
  font-size: 24px;
  text-transform: capitalize;
  line-height: 1;
}

.trainerProfile .profileHeader .industry {
  font-size: 14px;
  font-weight: 500;
  color: #e0a721;
}

.trainerProfile .profileHeader .social a {
  display: block;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #222;
  color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.25s;
}
.trainerProfile .profileHeader .social a svg {
  transition: all 0.25s;
  font-size: 18px;
}
.trainerProfile .profileHeader .social a:hover {
  scale: 1.1;
  border: 1px solid #e0a721;
  color: #e0a721;
}

.trainerProfile .profileHeader .star {
  color: #e0a721;
}
.trainerProfile .profileHeader .stars p {
  font-size: 13px;
}
.trainerProfile .card p {
  font-size: 14px;
}

@media screen and (max-width: 767.98px) {
  .trainerProfile .card,
  .trainerProfile .profileHeader.mx-4 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .trainerProfile .profileHeader .trainerName {
    font-size: 22px;
  }
  .trainerProfile .card .card-body {
    padding: 14px;
  }
  .trainerProfile .card .card-body h5 {
    font-size: 18px;
  }
  .trainerProfile .card .card-body hr {
    margin: 0.6rem 0;
  }
}
@media screen and (max-width: 575.98px) {
  .trainerProfile .stars {
    justify-content: center;
  }
  .trainerProfile .profileHeader .trainerName {
    text-align: center;
    font-size: 20px;
  }
  .trainerProfile .card .card-body {
    padding: 12px;
  }
  .trainerProfile .card .card-body h5 {
    font-size: 16px;
  }
  .trainerProfile .card .card-body hr {
    margin: 0.5rem 0;
  }
  .trainerProfile .profileHeader .profileImg {
    background-color: #fff;
    top: -60px;
    left: 50%;
    height: 120px;
    width: 120px;
    transform: translate(-50%, 0%);
  }
  .trainerProfile .profileHeader .social a {
    width: 30px;
    height: 30px;
  }
  .trainerProfile .profileHeader .social a img {
    height: 18px;
  }
  .trainerProfile .profileHeader .social a svg {
    font-size: 16px;
  }
}
