.demosEditSection {
  padding: 100px 0;
}
.demosEditSection h1 {
  font-size: 28px;
  font-family: "Unbounded", sans-serif;
  color: #444444;
  font-weight: 700;
}
.demosEditSection p {
  color: #e0a721;
  font-size: 16px;
  font-weight: 400;
}
.demosEditSection .demosEditList {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.demosEditSection .demosEditList .stepstriggers {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  color: #444444;
  font-weight: 500;
  margin: 0 6px;
  font-family: "Poppins", sans-serif;
  border: 1px solid #43a39f;
}
.demosEditSection .demosEditList .stepstriggers.text {
  font-size: 15px;
  color: #43a39f;
}

.demosEditSection .demosEditList .stepstriggers.active {
  background: #43a39f;
  color: #fff;
}
.demosEditForm {
  background: #fff !important;
  transition: all 0.5s ease;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.1);
  border-radius: 20px !important;
  padding: 20px 15px !important;
  max-width: 776px;
  width: 100%;
  margin: 0 auto;
}

.form-control::placeholder {
  font-size: 16px !important;
}
.add-button,
.demosEditBtn,
.coursesAddBtn {
  font-size: 14px;
  font-weight: 500;
  border: 2px solid transparent !important;
  background: #43a39f !important;
  color: #fff !important;
  text-align: center;
  border-radius: 2px !important;
  transition: all 0.35s;
  cursor: pointer;
}
.add-button:hover,
.coursesAddBtn:hover {
  border: 2px solid #43a39f !important;
  background-color: #fff !important;
  color: #43a39f !important;
}
.form-control::-webkit-inner-spin-button,
.form-control::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

/* media query */
@media screen and (max-width: 786.98px) {
  .demosAddForm {
    max-width: 100% !important;
    padding: 20px !important;
  }
  .demosEditSection h1 {
    font-size: 24px !important;
  }
}

@media screen and (max-width: 575.98px) {
  .demosAddForm {
    padding-top: 18px !important;
    padding-bottom: 18px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .demosEditSection h1 {
    font-size: 20px !important;
    margin: 10px 0px 10px 0 !important;
  }
  .form-section-header h3 {
    font-size: 21px;
    font-weight: 600;
  }

  .demosEditSection p {
    font-size: 12px !important;
  }
  .demosAddBtn {
    padding: 6px;
    font-size: 14px;
  }
  .demosEditForm {
    padding: 20px 0px !important;
  }
  .demosViewMoreSection .tab-content .tab-pane .card {
    padding: 0.5rem !important;
  }
  .demosViewMoreSection
    .tab-content
    .tab-pane
    .card
    .vidField
    .form-floating.select {
    width: 100% !important;
  }
}
@media screen and (max-width: 600.98px) {
  .add-button,
  .demosEditBtn,
  .coursesAddBtn {
    margin-bottom: 8px;
  }
}
