.navbar * {
  font-family: "Unbounded", sans-serif;
}
.navbar {
  display: flex;
  align-items: center;
  position: fixed !important;
  left: 0;
  right: 0;
  top: 0;
  z-index: 9999;
  backdrop-filter: blur(5px);
  background-color: #fff;
  border-bottom: 1px solid#eee;
}
.navbar .navbar-brand img {
  width: 160px;
  height: 70px;
  object-fit: contain;
}
.navbar-nav {
  gap: 22px;
}
.navbar .nav-link {
  color: #333;
  font-size: 15px;
  margin: 0;
  padding: 0 !important;
  line-height: normal;
  transition: all 0.3s;
  font-weight: 300;
  position: relative;
}
.navbar .navbar-nav .nav-link:hover {
  color: #42a49f;
  transition: all 0.3s;
}
.navbar .nav-link.active-link {
  font-weight: bold;
  color: #42a49f !important;
  transition: all 0.3s;
}
.navbar .nav-link::before {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -4px;
  background-color: #42a49f;
  transition: all 0.3s;
}
.navbar .navbar-nav .nav-link:hover::before,
.navbar .nav-link.active-link::before {
  content: "";
  width: 18px;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -4px;
  background-color: #42a49f;
  transition: all 0.3s;
}
.btn.postTrigger.active-link,
.btn.postTrigger:hover,
.btn.postTrigger:focus-visible {
  background-color: #e0a721;
  color: #fff;
}
.btn.postTrigger {
  font-size: 14px;
  color: #e0a721;
  margin: 0;
  padding: 8px 18px;
  line-height: normal;
  transition: all 0.3s;
  font-weight: 500;
  border-radius: 2px;
  background-color: transparent;
  border: 2px solid #e0a721 !important;
}
.navbar .dropDownImg,
.navbar .dropDownImg img,
.profile.demosViewMoreSection .profileHeader .profileImg {
  object-fit: cover;
  object-position: 50% 30%;
}
.navbar .dropDownImg,
.navbar .dropDownImg img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.navbar .dropdown-menu[data-bs-popper] {
  left: unset;
  right: 0;
  background-color: #fff !important;
  z-index: 999;
}
.navbar .dropdown-item {
  font-size: 14px;
  background-color: #fff !important;
}
.navbar .dropdown-toggle::after {
  display: none !important;
}
.offcanvas-backdrop.show,
.offcanvas {
  z-index: 99999 !important;
}
@media (max-width: 1399.98px) {
  .navbar .nav-link {
    font-size: 14px;
  }
}
@media (max-width: 1199.98px) {
  .navbar .navbar-brand img {
    width: 120px;
    height: 56px;
  }
  .navbar-nav {
    gap: 0;
  }
  .navbar .nav-link {
    margin: 7px;
  }
  .btn.postTrigger {
    font-size: 12px;
    text-wrap: nowrap;
    padding: 8px 16px;
    border: 1.5px solid #e0a721 !important;
  }
  .navbar .dropDownImg,
  .navbar .dropDownImg img {
    width: 44px;
    height: 44px;
  }
}
@media (max-width: 991.98px) {
  .dropdown-menu {
    overflow-x: hidden;
  }
}
@media (max-width: 767.98px) {
  .navbar .navbar-brand img {
    width: 105px;
    height: 54px;
  }
  .navbar .dropDownImg,
  .navbar .dropDownImg img {
    width: 38px;
    height: 38px;
  }
  .navbar .dropdown-item {
    font-size: 13px;
  }
  .dropdown-item.nav-link.active-link {
    font-weight: bold;
    color: #42a49f !important;
    transition: all 0.3s;
  }
}
@media (max-width: 575.98px) {
  .navbar .navbar-brand img {
    width: 120px;
    height: 52px;
  }
  .navbar .navbar-toggler {
    padding: 4px 6px;
    font-size: 1rem;
  }
  .navbar .dropDownImg,
  .navbar .dropDownImg img {
    width: 34px;
    height: 34px;
  }
  .navbar .nav-link {
    margin: 6px;
    font-size: 12px;
  }
  .btn.postTrigger {
    font-size: 12px;
    padding: 6px 16px;
  }
}
