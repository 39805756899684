.gallery .lg-react-element {
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
}
.gallery .lg-react-element .slider-img-btn {
  max-width: 408px;
}
.gallery .gallery-image-label {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #0000001a;
  color: #fff;
  border: none;
  box-shadow: none;
  backdrop-filter: blur(3px);
}
@media screen and (max-width: 1023.98px) {
  .gallery .lg-react-element .slider-img-btn {
    max-width: 400px;
  }
}
@media screen and (max-width: 991.98px) {
  .gallery .lg-react-element .slider-img-btn {
    max-width: 312px;
  }
}
@media screen and (max-width: 768.98px) {
  .gallery .lg-react-element {
    gap: 14px;
  }

  .gallery .lg-react-element .slider-img-btn {
    max-width: 48%;
  }
}
@media screen and (max-width: 600.98px) {
  .lg-content .lg-prev,
  .lg-content .lg-next {
    width: fit-content;
  }
}
