.app {
  overflow-x: hidden;
}
.loginformMain {
  position: relative;
  background: url(../../img/2942004.png);
  background-size: 50vw;
  background-position: 85%;
  background-repeat: no-repeat;
  min-height: 100vh;
  display: flex;
  align-items: center;
  width: 100%;
}
.loginformMain::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  backdrop-filter: blur(3px);
  background-color: #21434265;
  z-index: 1;
}

.form-wrapper {
  background-color: #fff;
  min-height: 100vh;
  height: 100%;
  max-width: 540px;
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.form-input-wrapper {
  padding-left: 7.5px;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  width: 520px;
  margin: 0 auto;
  overflow-x: hidden;
}
.form-wrapper.signup .form-input-wrapper .form-login,
.form-wrapper.signup .form-input-wrapper .form-signup {
  transform: translateX(calc(-100% - 20px));
  transition: all 0.35s ease-in-out;
}
.form-wrapper .form-input-wrapper .form-floating .form-control {
  padding: 4px 12px !important;
  min-height: 44px !important;
}
.form-wrapper .form-input-wrapper .form-floating input.form-control {
  max-height: 44px !important;
}
.form-wrapper .form-input-wrapper option {
  font-size: 14px;
  font-weight: 400;
}
.form-wrapper .form-input-wrapper select {
  font-size: 12px;
}
.form-wrapper .form-input-wrapper .form-floating > label {
  font-size: 11px;
  font-weight: 500;
}
.form-login {
  max-width: 500px;
  min-width: 500px;
  min-height: 100%;
  transition: all 0.35s ease-in-out;
}
.form-signup {
  max-width: 500px;
  min-width: 500px;
  min-height: 100%;
  transition: all 0.35s ease-in-out;
}
.password-group .passwordToggler {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  color: #000;
}

.loginformMain .form-toggle {
  width: 100%;
  position: relative;
  z-index: 2;
  padding: 0 20px;
}
.loginformMain .form-bottom {
  padding: 0 20px;
}

.loginformMain .form-toggle .toggle-btn {
  background: white;
  padding: 8px;
  max-width: 120px;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #43a39f;
  border: solid 2px transparent;
  transition: all 0.35s ease-in-out;
}
.loginformMain .form-toggle .toggle-btn:hover {
  color: #fff;
  background: transparent;
  border: #fff 2px solid;
  transition: all 0.35s ease-in-out;
}

.button-login-option {
  width: 45px;
  height: 45px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  font-size: 18px;
  background: transparent !important;
  transition: all 0.35s ease;
}
.button-login-option.btngoogle {
  color: #eb493b;
  border: solid 2px #eb493b;
}
.button-login-option.btngithub {
  color: #1f2227;
  border: solid 2px #1f2227;
}
.button-login-option.btnlinkedin {
  color: #2f6cb4;
  border: solid 2px #2f6cb4;
}
.button-login-option.btngoogle:hover {
  background: #eb493b !important;
  border: inherit;
}
.button-login-option.btngithub:hover {
  background: #1f2227 !important;
  border: inherit;
}
.button-login-option.btnlinkedin:hover {
  background: #2f6cb4 !important;
  border: inherit;
}

.button-login-option:hover {
  color: #fff;
  border: solid 2px transparent;
}
.form-wrapper .headertxt {
  font-size: 22px;
}
.form-toggle-sm {
  display: none !important;
}

/* Foeger Modal */
.forgot_modal {
  max-width: 480px;
  width: 100%;
  background-color: #fff;
  color: #000;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  padding: 28px;
}
.forgot_modal .closeModal {
  position: absolute;
  right: 8px;
  top: 8px;
  cursor: pointer;
}
.forgot_modal p {
  font-size: 12px;
}
@media screen and (max-width: 1199.98px) {
  .loginformMain {
    background-size: 40vw;
    background-position: 95%;
  }
  .button-login-option {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }
  .form-wrapper .headertxt {
    font-size: 18px;
  }
  .forgot_modal .actionBtnMore {
    font-size: 12px;
  }
  .forgot_modal .form-floating .form-control,
  .form-wrapper .form-input-wrapper .form-floating .form-control {
    padding: 4px 12px !important;
    min-height: 40px !important;
  }
  .forgot_modal .form-floating input.form-control,
  .form-wrapper .form-input-wrapper .form-floating input.form-control {
    max-height: 40px !important;
  }
  .forgot_modal .form-floating > label,
  .form-wrapper .form-input-wrapper .form-floating > label {
    font-size: 10px;
    padding: 14px 0.75rem;
  }
  .form-wrapper .form-toggle .add-button,
  .form-wrapper .add-button.btn-signup {
    font-size: 14px;
    padding: 8px 0;
  }
}
@media screen and (max-width: 991.98px) {
  .form-toggle-lg {
    display: none;
  }
  .form-toggle-sm {
    display: block !important;
  }
  .form-wrapper {
    max-width: 100%;
  }
}
@media screen and (max-width: 600px) {
  .password-group .passwordToggler {
    left: 43%;
  }
}
@media screen and (max-width: 575.98px) {
  .form-wrapper .form-input-wrapper .form-floating .form-control {
    min-height: 44px !important;
  }
  .form-wrapper .form-input-wrapper .form-floating input.form-control {
    max-height: 44px !important;
  }
  .form-input-wrapper {
    padding-left: 8px;
  }
  .form-signup,
  .form-login {
    max-width: 95vw;
    min-width: 95vw;
  }
  .forgot_modal {
    padding: 24px 14px;
  }
  .forgot_modal p {
    font-size: 10px;
  }
}
