.featured-blog-posts {
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.2);
  border-radius: 12px;
  position: sticky;
  top: 0;
}
.featured-blog-card .featured-card-image {
  max-height: 120px;
}
.blog-card-image,
.featured-blog-card .featured-card-image {
  min-height: 100%;
  border-radius: 6px;
}
.blog-card-image img,
.featured-blog-card .featured-card-image img {
  object-fit: cover;
  border-radius: inherit;
}
.featured-blog-card .featured-card-content p {
  font-size: 16px;
  margin: 0;
  margin-bottom: 4px;
}
.featured-blog-card .featured-card-content .time {
  font-size: 12px;
  opacity: 0.75;
  padding-bottom: 6px;
}
.blog-card {
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.2);
  border-radius: 12px;
}
.blog-card-image {
  max-height: 240px;
}

.blog-card .chip {
  border: 1px solid #eee;
  line-height: 1;
  padding: 4px 8px !important;
  font-size: 12px;
}

.blog-card h5.blog-title {
  font-size: 1.25rem;
  line-height: 1.4;
  margin-top: 0;
  margin-bottom: 1.25rem;
}
.blog-card .blog-desc {
  margin-bottom: 1rem;
  line-height: 1.4;
  color: #555;
}
.blog-details .hero-image {
  border-radius: 6px;
  max-height: 28rem;
}

.blog-details .hero-image img {
  border-radius: inherit;
}

@media (max-width: 575.98px) {
  .blog-card h5.blog-title {
    font-size: 20px;
    margin-bottom: 12px;
  }
  .blog-card .blog-desc {
    font-size: 13px;
    margin-bottom: 8px;
  }
  .blog-details .main-title {
    font-size: 20px;
  }
  .blog-card .blog-time .time,
  .blog-details p {
    font-size: 12px;
  }
  .blog-details .image-wrapper img {
    max-width: 190px;
  }

  .featured-blog-card .featured-card-content p {
    font-size: 14px;
    margin-top: 4px;
  }
}
