.subsSec .card {
  background: #fff;
  color: #333;
  border-radius: 0.8rem;
  border: 0;
}

.subsSec .cards .card.active {
  background: #43a39f;
  color: #fff;
  display: flex;
  align-items: center;
  transform: scale(1.1);
  z-index: 1;
  position: relative;
  overflow: hidden;
}
.subsSec .cards .card.active:after {
  content: "Popular 🔥";
  font-size: 14px;
  position: absolute;
  right: 0;
  top: 10px;
  border-radius: 2px 0 0 2px;
  background-color: #e0a721;
  padding: 3px 10px;
  font-weight: 500;
}
.subsSec .cards .card .pack {
  font-size: 18px;
  font-weight: 500;
  color: #e0a721;
}
.subsSec ul li.price {
  font-family: "Unbounded", sans-serif;
  position: relative;
  font-size: 32px;
  color: #e0a721;
  padding-bottom: 2rem;
  font-weight: 500;
}
.subsSec ul li.price::after {
  content: "/month";
  color: inherit;
  font-size: 12px;
}
.subsSec .shadow {
  box-shadow: -3px -3px 5px 9px rgba(0, 0, 0, 0.122);
}

.subsSec .cards .card.active .pack,
.subsSec .card.active .price {
  color: #fff;
}

.subsSec .btn-sub {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  background: #43a39f;
  width: fit-content;
  margin: 0 auto;
  margin-top: 1.5rem;
  color: #fff;
  outline: none;
  border: 0;
  font-weight: bold;
  padding: 8px 18px;
  border: 3px solid transparent;
}
.subsSec .active-btn {
  background: #fff;
  color: #43a39f;
  border: 3px solid #fff;
}
.subsSec .btn-sub:hover {
  background: inherit;
  color: #43a39f;
  border: 3px solid #43a39f;
}
.subsSec .active-btn:hover {
  background: #43a39f;
  color: #fff;
  border: 1px solid #fff;
}
.subsSec .bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.582);
}
.subsSec .card.active .bottom-bar {
  border-bottom: 2px solid hsla(240, 8%, 85%, 0.253);
}

@media (max-width: 280px) {
  .subsSec ul {
    margin: 1rem;
  }
  .subsSec h1 {
    font-size: 1.2rem;
  }
  .subsSec .toggle {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 80px;
  }

  .subsSec .card {
    transform: scale(0.8);
    margin-bottom: 1rem;
  }
  .subsSec .cards .card.active {
    transform: scale(0.8);
  }
}

@media (min-width: 280px) and (max-width: 320px) {
  .subsSec ul {
    margin: 20px;
  }
  .subsSec .card {
    margin-bottom: 1rem;
  }
  .subsSec .cards .card.active {
    transform: scale(1);
  }
}

@media (min-width: 320px) and (max-width: 414px) {
  .subsSec .card {
    margin-bottom: 1rem;
  }
  .subsSec .cards .card.active {
    transform: scale(1);
  }
}
@media (min-width: 414px) and (max-width: 768px) {
 .subsSec .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
 .subsSec .cards .card.active {
    transform: scale(1);
  }
}
@media (min-width: 768px) and (max-width: 1046px) {
  .subsSec .card {
    margin-bottom: 1rem;
    margin-right: 1rem;
  }
 .subsSec .cards .card.active {
    transform: scale(1);
  }
}
