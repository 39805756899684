.container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

@media screen and (max-width: 600px) {
  .form-container {
    width: 100%;
  }

  input[type="text"] {
    margin-right: 0;
    border-radius: 0;
  }

  button {
    width: 100%;
  }
}
