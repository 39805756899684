.dashboardSec .dashboard-nav {
  border-right: 1px solid #ddd;
  max-width: 90px;
  width: 100%;
}

.dashboardSec .dashboard-nav .nav-item .nav-link {
  color: #222;
  font-size: 12px;
  padding: 4px;
  margin: 12px 0;
  position: relative;
  font-weight: 500;
}
.dashboardSec .dashboard-nav .nav-item .nav-link svg {
  font-size: 26px;
}
.dashboardSec .dashboard-nav .nav-link::before {
  content: "";
  width: 0;
  height: 2px;
  position: absolute;
  left: 0;
  bottom: -4px;
  background-color: #42a49f;
  transition: all 0.3s;
}
.dashboardSec .dashboard-nav .nav-link:hover::before,
.dashboardSec .dashboard-nav .nav-link.active::before {
  content: "";
  width: 20px;
  height: 2px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0px;
  background-color: #42a49f;
  transition: all 0.3s;
}
.dashboardSec .dashboard-nav .nav-item .nav-link.active {
  background-color: transparent;
  border-radius: 0;
  color: #43a39f;
}
.add-group .custom-checkbox .form-check-label::before {
  top: 50%;
  transform: translateY(-50%);
}
.my-groups .striped .action-wrap {
  width: 140px;
}
@media (max-width: 767.98px) {
  .dashboardSec .dashboard-nav {
    border-bottom: 1px solid #ddd;
    max-width: 100%;
    border-right: none;
  }
  .dashboardSec .dashboard-nav .nav-item .nav-link{
    margin: 0;
  }
}

@media (max-width: 575.98px) {
  .dashboardSec .dashboard-nav .nav-item .nav-link {
    font-size: 12px; 
  }
  .dashboardSec .heading-text h4 {
    font-size: 18px;
  }
  .dashboardSec .heading-text p {
    font-size: 12px;
  }
}
