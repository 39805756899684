.chatAI {
  min-height: 60vh;
}
.chatAI * {
  transition: all 0.35s;
}

.chatAI .inputGroup {
  border-radius: 6px;
  overflow: hidden;
  padding: 6px;
  border: 1px solid #777;
  box-shadow: rgba(85, 87, 88, 0.1) 0px 8px 24px;
  display: flex;
  align-items: center;
}
.chatAI .inputGroup .form-control {
  background: transparent;
  box-shadow: unset;
  border: 1px solid transparent;
  color: #000;
  min-height: 42px !important;
  max-height: 42px !important;
}
.chatAI .inputGroup .form-control:focus {
  border: none;
  box-shadow: none;
}
.chatAI .inputGroup .form-control::placeholder {
  color: #444;
}
.chatAI .input-group-text {
  border: none !important;
  border-radius: 50% !important;
  background: #42a49f !important;
  color: #fff !important;
  height: 42px !important;
  width: 42px !important;
  justify-content: center;
}

.chatAI .chat-container .card .card-header {
  background-color: #202123;
  font-size: 14px;
}
.chatAI .chat-container .card .card-header .btn {
  font-size: 14px;
}

.chatAI .Toastify__toast-container {
  width: fit-content !important;
}
.chatAI .typing-loader {
  width: 40px;
  aspect-ratio: 4;
  --_g: no-repeat radial-gradient(circle closest-side, #42a49f 90%, #42a49f0e);
  background: var(--_g) 0% 50%, var(--_g) 50% 50%, var(--_g) 100% 50%;
  background-size: calc(100% / 3) 100%;
  animation: typing 1s infinite linear;
}
@keyframes typing {
  33% {
    background-size: calc(100% / 3) 0%, calc(100% / 3) 100%, calc(100% / 3) 100%;
  }
  50% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 0%, calc(100% / 3) 100%;
  }
  66% {
    background-size: calc(100% / 3) 100%, calc(100% / 3) 100%, calc(100% / 3) 0%;
  }
}

@media (max-width: 767.98px) {
  .chatAI .demosViewMoreForm {
    padding: 20px 8px !important;
  }
  .chatAI .inputGroup {
    border-width: 1px;
    padding: 4px;
    font-size: 14px !important;
    min-height: auto !important;
    height: auto !important;
  }
  .chatAI .inputGroup .form-control {
    min-height: 38px !important;
    max-height: 38px !important;
  }
  .chatAI .input-group-text {
    width: 36px !important;
    height: 36px !important;
  }
  .chatAI .chat-container .botImg,
  .chatAI .chat-container .userImg {
    width: 28px !important;
    height: 28px !important;
  }
  .chatAI .chat-container .card code {
    font-size: 10px;
  }
}
