.demoCard.card .itemName .detailLink {
  font-family: "Unbounded", sans-serif;
  color: #43a39f;
  text-decoration: none;
  font-size: 16px;
  text-transform: capitalize;
  line-height: normal;
  display: inline-block;
  margin: 0;
  text-wrap: balance;
}
.demoCard.card {
  min-height: 100%;
  border-radius: 12px;
  padding: 8px 8px 16px 8px;
  text-wrap: balance;
  border: none;
  transition: all 0.5s ease;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.2);
}
.demoCard.card .itemImg .linkImg {
  overflow: hidden;
  border-radius: 8px;
  background-color: #fff;
  position: relative;
  z-index: 1;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.06);
  border: 2px solid #eeeeeeb0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 0;
  padding-bottom: 64.2857%;
  position: relative;
  overflow: hidden;
}
.demoCard.card .itemImg .linkImg img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  object-position: center;
  object-fit: cover;
}
.demoCard.card .headDetail b {
  font-size: 14px;
  color: #e0a721;
}
.demoCard.card .card-text b {
  font-size: 14px;
}

.demoCard.card .card-text img {
  width: 26px;
}
.demoCard.card .itemInfo .actionBtn {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid transparent;
  background-color: #43a39f;
  text-align: center;
  border-radius: 4px;
  color: #fff;
  width: fit-content;
  transition: all 0.35s;
}
.demoCard.card .itemInfo .actionBtn:hover {
  color: #43a39f;
  background: transparent;
  border: 2px solid #43a39f;
}
.actionDltBtn {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  background-color: #e02128;
  color: #fff;
  border: 2px solid transparent;
  transition: all 0.35s;
}
.actionDltBtn:hover {
  border: 2px solid #e02128;
  color: #e02128;
  background-color: transparent;
}
@media screen and (max-width: 575.98px) {
  .demoCard.card .itemInfo .card-text {
    font-size: 12px;
  }
  .demoCard.card .itemInfo .actionBtn,
  .actionDltBtn {
    font-size: 12px;
  }
  .add-button,
  .demosEditBtn,
  .coursesAddBtn {
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 13px !important;
    cursor: pointer;
  }
  .add-button.form-control {
    font-size: 13px !important;
    padding-top: 8px;
    padding-bottom: 8px;
    min-height: 35px !important;
    max-height: 35px !important;
  }
}
