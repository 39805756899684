.react-calendar ~ .form-floating,
.react-calendar {
  max-width: 380px;
  width: 100%;
  border: none;
}
.react-calendar
  .react-calendar__navigation__arrow.react-calendar__navigation__prev2-button,
.react-calendar
  .react-calendar__navigation__arrow.react-calendar__navigation__next2-button {
  display: none;
}
.react-calendar__navigation__arrow {
  background-color: #f2f5fe !important;
  border-radius: 50%;
  min-width: auto !important;
  width: 46px;
  height: 46px;
  padding: 0;
  display: flex;
  align-items: center;
  color: #43a39f;
  justify-content: center;
  font-size: 16px;
}

.react-calendar .react-calendar__navigation {
  justify-content: center;
  gap: 12px;
  align-items: center;
}
.react-calendar .react-calendar__navigation__label {
  flex-grow: inherit !important;
}
.react-calendar
  .react-calendar__navigation__label
  .react-calendar__navigation__label__labelText {
  font-size: 19px !important;
}
.react-calendar .react-calendar__month-view__weekdays__weekday abbr[title] {
  font-size: 15px;
  text-decoration: none;
  font-weight: 500;
  color: #000;
}

.react-calendar__tile.react-calendar__tile--now.react-calendar__tile--active,
.react-calendar .react-calendar__tile:hover {
  outline-offset: -3px;
  outline: 1px solid #43a39f;
  color: #43a39f;
  background-color: transparent !important;
}

.react-calendar .react-calendar__tile {
  padding: 0;
  font-size: 16px;
  color: #333;
  width: 54px;
  height: 51px;
  display: flex;
  font-weight: normal;
  background-color: transparent !important;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  outline: 1px solid transparent;
  outline-offset: -1px;
}

.react-calendar .react-calendar__tile:focus,
.react-calendar .react-calendar__tile--active {
  background-color: #43a39f !important;
  color: white;
  font-weight: 600;
  scale: 0.9;
}

.alert-theme {
  --bs-alert-color: #43a39f !important;
  --bs-alert-bg: #43a3a00e !important;
  --bs-alert-border-color: #43a39f !important;
  --bs-alert-link-color: #43a39f !important;
}
.alert-theme-2 {
  --bs-alert-color: #e0a721 !important;
  --bs-alert-bg: #e0a72110 !important;
  --bs-alert-border-color: #e0a721 !important;
  --bs-alert-link-color: #e0a721 !important;
}
.form-floating input[type="button"] {
  text-align: start;
}
.modal .custom-checkbox label {
  font-size: 14px;
}
.batch .form-floating .timezone,
.modal #timezone {
  padding: 0 !important;
}

.react-datepicker-popper {
  width: 100% !important;
  z-index: 999 !important;
}
.react-datepicker-wrapper,
.react-datepicker,
.react-datepicker--time-only .react-datepicker__time-container,
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box {
  width: 100% !important;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  font-size: 14px;
  text-align: left;
}
.react-datepicker__header {
  display: none;
}
.react-datepicker-popper .react-datepicker__triangle {
  transform: translate(-50%, 0px) !important;
  left: 50% !important;
}
.modal .overlayCalender {
  backdrop-filter: blur(1.5px);
  z-index: 1000;
}
