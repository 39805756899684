body,
html {
  overflow-x: hidden;
}
* {
  box-sizing: border-box;
}
@media screen and (min-width: 1440px) {
  .dashboardSec .container-lg,
  .footerSection .container-lg {
    max-width: 90%;
  }
  .navbar .container-xl {
    max-width: 95%;
  }
  .footerSection .inputGroup {
    width: 400px !important;
  }
  .sidebar ~ .container-lg {
    max-width: 84%;
  }
}
/* Modal triggered */
.modal-open .navbar.pad {
  padding-right: 17px;
}

/* general */
.section {
  padding: 80px 0 90px 0;
}
.main-heading {
  font-family: "Unbounded", sans-serif;
  font-size: 46px;
  font-weight: 600;
  margin-bottom: 40px;
}
.h-0 {
  height: 0;
}
.bg-theme {
  background-color: #42a49f !important;
}
.bg-theme-2 {
  background-color: #e0a721 !important;
}
.theme-text {
  color: #42a49f !important;
}
.theme-text-2 {
  color: #e0a721 !important;
}
.semi-bg {
  background-color: #35797512;
}
.max-h-auto {
  max-height: max-content !important;
}
.text-wrap-balance {
  text-wrap: balance;
}
.btn-check:checked + .btn,
.btn.active,
.btn.show,
.btn:first-child:active,
:not(.btn-check) + .btn:active {
  color: currentColor !important;
  background-color: inherit !important;
}
.form-floating textarea.form-control,
textarea,
textarea.form-control {
  max-height: initial !important;
  height: auto;
}
.tag-chip {
  font-size: 10px;
  background-color: #9999990c;
  border-radius: 4px;
  padding: 1px 3px;
  color: #666;
  font-weight: 500;
  border: 1px solid#99999921;
}
.card a.entity-share-btn,
a.entity-share-btn {
  height: 36px;
  width: 36px;
  padding: 0;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-control.timezone .css-1fdsijx-ValueContainer,
.form-control.timezone .css-t3ipsp-control,
.form-control.timezone .css-13cymwt-control {
  border: none;
  height: 100%;
}

.actionBtnMain {
  width: 115px !important;
}
.card a.entity-share-btn,
a.entity-share-btn {
  width: 26px;
  height: 26px;
  font-size: 12px;
  background-color: #42a49f;
  border: 1px solid transparent;
  color: #fff;
  transition: all 0.25s;
}

.card a.entity-share-btn:hover,
a.entity-share-btn:hover {
  color: #42a49f;
  background-color: transparent;
  border: 1px solid #43a39f;
}

.contacts .css-80pr5n-MuiPaper-root {
  box-shadow: none !important;
}
.contacts .css-120bgvu-MuiTableHead-root {
  background-color: #43a39f;
}
.contacts .css-120bgvu-MuiTableHead-root * {
  background-color: transparent;
  color: white;
}
.contacts .css-120bgvu-MuiTableHead-root th,
.contacts .css-1lmezb1-MuiTableBody-root td {
  padding: 8px !important;
  width: max-content !important;
  min-width: fit-content !important;
}
.contacts .css-zw3mfo-MuiModal-root-MuiDialog-root {
  z-index: 9999;
}
.message-sm {
  font-size: 13px;
  text-wrap: balance;
  line-height: 1.3;
}
@media screen and (max-width: 1199.98px) {
  .main-heading {
    font-size: 34px;
    margin-bottom: 32px;
  }
  .section {
    padding: 60px 0 90px 0;
  }
}
@media screen and (max-width: 991.98px) {
  .main-heading {
    font-size: 32px;
    margin-bottom: 28px;
  }
  .modal-open .navbar.pad {
    padding-right: 0;
  }
  .overflow-x-lg-scroll {
    overflow-x: scroll;
  }
}
@media screen and (max-width: 767.98px) {
  .section {
    padding: 50px 0 70px 0;
  }
  .main-heading {
    font-size: 28px;
    margin-bottom: 24px;
  }
  .overflow-x-md-scroll {
    overflow-x: scroll;
  }
}
@media screen and (min-width: 767px) {
  .w-md-fit {
    width: fit-content !important;
  }
  .w-md-fit select.form-select {
    padding-right: 38px !important;
  }
}

@media screen and (max-width: 575.98px) {
  .section {
    padding: 40px 0 50px 0;
  }
  .main-heading {
    font-size: 24px;
  }
  .card a.entity-share-btn,
  a.entity-share-btn {
    width: 20px;
    height: 20px;
    font-size: 10px;
  }
  .actionBtnsDash {
    width: 100% !important;
  }
  p {
    font-size: 12px;
  }
  .page404 h1 {
    font-size: 20px;
  }
  .page404 h3 {
    font-size: 16px;
  }
  .page404 h5 {
    font-size: 12px;
  }
}
