.form {
  background-color: #eee;
  margin: auto !important;
  margin-top: 60px !important;
  padding: 40px !important;
  border-radius: 39px !important;
  box-shadow: 5px 11px 11px 5px rgba(0, 0, 0, 0.3) !important;
}

.theme-text-2 label {
  color: inherit !important;
}
label {
  color: #000 !important;
}
.button2 {
  background-color: #43a39f !important;
  color: white !important;
  border: none !important;
  width: 58%;
}

.button2:hover {
  background-color: white !important;
  color: #43a39f !important;
  font-weight: 700 !important;
}

.button:hover {
  background-color: white !important;
  color: #43a39f !important;
  font-weight: 700 !important;
}
.form label {
  top: -5px !important;
  color: #000 !important;
  opacity: 0.5;
  font-weight: 100 !important;
}

.step-buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.step-button {
  background-color: #f5f5f5;
  border: none;
  color: #000;
  padding: 10px 20px;
  margin-right: 10px;
  cursor: pointer;
  font-weight: bold;
  outline: none;
}

.step-button.active {
  background-color: #43a39f;
  color: #fff;
}

.form-floating .form-control {
  padding: 10px 12px !important;
  min-height: 50px !important;
  border: 1px solid #00000022;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
}
.form-floating .css-b62m3t-container.form-control,
.form-floating select.form-control,
.form-floating input.form-control {
  max-height: 50px !important;
}
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown),
.form-floating > .form-control:focus,
.form-floating > .form-control:not(:placeholder-shown) {
  border: 1px solid #43a3a0c3 !important;
}
.form-floating > label {
  font-size: 12px;
  font-weight: 500;
  top: -1px;
  color: #444444 !important;
}
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  transform: scale(0.85) translateY(-1.5rem) translateX(0.15rem) !important;
  color: #43a39f !important;
}
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-select ~ label::after {
  background-color: #fff !important;
}

.form-label {
  font-size: 14px;
  font-weight: 500;
  color: #444444;
}
.form-control,
.form-select {
  padding: 10px 12px !important;
  padding-right: 30px !important;
  min-height: 45px !important;
  max-height: 45px !important;
  font-size: 14px;
  border: 1px solid #00000022;
  box-shadow: none !important;
  font-family: "Poppins", sans-serif;
}
.form-control:focus,
.form-select:focus {
  border: 1px solid #43a39f;
}

.it-placeholder::placeholder {
  font-weight: 500 !important;
  font-size: 14px !important;
}
.form-floating .form-control.border-danger,
.form-floating .form-select.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}
/* Demos page  view more section  starts here*/

.demosViewMoreForm {
  background: #fff !important;
  transition: all 0.5s ease;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.1);
  border-radius: 20px !important;
  padding: 20px 24px !important;
  max-width: 776px;
  width: 100%;
  margin: 40px auto;
  border: none;
}
.demosViewMoreForm p {
  font-size: 15px;
  font-weight: 600;
  color: #444444;
}
.demosViewMoreForm .row {
  border-bottom: 1px solid #43a39f !important ;
}
/* Demos page  view more section  ends here*/
/*     Add Arrow Buttons */
.leftbtn {
  float: left;
}
.rightbtn {
  float: right;
}

@media screen and (max-width: 575.98px) {
  .form-floating .form-control {
    padding: 6px 10px !important;
    min-height: 44px !important;
  }
  .form-floating .css-b62m3t-container.form-control,
  .form-floating input.form-control {
    max-height: 44px !important;
  }
}
