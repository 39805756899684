.instituteCard.card {
  position: relative !important;
  background: #6e6e6e80;
  border-radius: 0;
  border: none !important;
  min-height:100%;
}
.instituteCard.card::before {
  content: "";
  position: absolute;
  z-index: 1;
  top: 8px;
  right: 8px;
  background: #fff;
  width: calc(100% - 16px);
  height: calc(100% - 16px);
}
.instituteCard.card::after {
  content: "";
  position: absolute;
  z-index: 1;
  top: -1px;
  right: -1px;
  background: #fff;
  width: 50%;
  height: calc(100% + 2px);
}
.instituteCard.card .badge-card {
  position: absolute;
  top: 14px;
  left: 14px;
  z-index: 2;
  background-color: #42a49fbb;
  background-clip: text;
  -webkit-background-clip: text;
  color: #fff;
  font-weight: 700;
  font-size: 22px;
  -webkit-text-stroke-color: transparent;
  -webkit-text-stroke-width: 4px;
}
.instituteCard.card .card-body {
  position: relative;
  z-index: 2;
  padding: 54px 20px 34px 20px;
}
.instituteCard.card .card-title a{
  font-size: 24px;
  text-decoration: none;
  color: inherit;
  margin-bottom: 30px;
}
.instituteCard.card .btn-more.btn {
  position: relative;
  padding: 10px 22px;
  font-size: 14px;
  display: inline-block;
  background-color: transparent;
  color: #333;
  transition: all 0.25s ease;
  border: 1px solid transparent;
  width: fit-content;
}
.instituteCard.card .btn-more.btn::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: calc(100% - 44px);
  height: 80%;
  border: 2px solid transparent;
  border-bottom-color: #333;
  transform: translate3d(-50%, -50%, 0);
  transition: width 0.25s ease, height 0.25s ease;
}
.instituteCard.card .btn-more.btn:hover {
border: none;
background-color: transparent;
}
.instituteCard.card:hover .btn-more.btn {
  color: #42a49f;
}
.instituteCard.card:hover .btn-more.btn::after {
  color: #42a49f;
  border: 2px solid #42a49f;
  height: 100%;
  width: 100%;
  transition: border-color 0.3s ease-in-out, width 0.25s ease, height 0.25s ease;
}
@media screen and (max-width: 1199.98px) {
  .instituteCard.card .card-body {
    padding: 40px 20px 20px 20px;
  }
   .instituteCard.card .card-title a{
    font-size: 22px;
    margin-bottom: 20px;
  }
  .instituteCard.card .card-body p {
    font-size: 14px;
  }
}
@media screen and (max-width: 767.98px) {
  .instituteCard.card .card-body {
    padding: 34px 18px;
  }
  .instituteCard.card .badge-card {
    font-size: 18px;
  }

  .instituteCard.card .card-title {
    font-size: 26px;
  }
  .instituteCard.card .card-body p {
    font-size: 13px;
  }
  .instituteCard.card .btn-more {
    padding: 8px 22px;
  }
}
@media screen and (max-width: 575.98px) {
  .instituteCard.card .card-body {
    padding: 32px 18px;
  }
  .instituteCard.card .badge-card {
    font-size: 16px;
  }

  .instituteCard.card .card-title {
    font-size: 18px;
  }
  .instituteCard.card .card-body p {
    font-size: 12px;
  }
  .instituteCard.card .btn-more {
    padding: 8px 22px;
    font-size: 12px;
  }
  .instituteCard.card::before {
    content: "";
    top: 6px;
    right: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
  }
}
