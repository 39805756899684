body {
  background-color: #ffffff;
  color: #444444;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

.wizard-content-left {
  background-blend-mode: darken;
  background-image: url("https://images.unsplash.com/photo-1561016444-14f747499547?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxleHBsb3JlLWZlZWR8MTV8fHxlbnwwfHx8fHw%3D&w=1000&q=80");
  background-position: center center;
  background-size: cover;
  height: 84vh;
  padding: 30px;
}

.wizard-content-left h1 {
  font-size: 36px;
  font-weight: bold;
  text-align: center;
  color: white;
  text-shadow: 2px 2px 0px black;
}

.clearfix {
  display: flex;
  justify-content: center;
}

.form-wizard {
  color: #888888;
  padding: 5% 10%;
  margin-top: -78px;
}

.form-wizard form {
  background-color: white;
  margin: auto !important;
  margin-top: 5% !important;
  padding: 5% !important;
  border-radius: 5px !important;
  box-shadow: -9px 11px 23px 15px rgba(0, 0, 0, 0.3) !important;
}

.form-wizard .wizard-form-radio {
  display: inline-block;
  margin-left: 5px;
  position: relative;
}

.form-wizard .wizard-form-radio input[type="radio"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  background-color: #dddddd;
  height: 25px;
  width: 25px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.form-wizard .wizard-form-radio input[type="radio"]:focus {
  outline: 0;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked {
  background-color: #66bb6a; /* #43a39f color */
}

.form-wizard .wizard-form-radio input[type="radio"]:checked::before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  display: inline-block;
  background-color: #ffffff;
  border-radius: 50%;
  left: 1px;
  right: 0;
  margin: 0 auto;
  top: 8px;
}

.form-wizard .wizard-form-radio input[type="radio"]:checked::after {
  content: "";
  display: inline-block;
  -webkit-animation: click-radio-wave 0.65s;
  -moz-animation: click-radio-wave 0.65s;
  animation: click-radio-wave 0.65s;
  background: #000000;
  content: '';
  display: block;
  position: relative;
  z-index: 100;
  border-radius: 50%;
}

.form-wizard .wizard-form-radio input[type="radio"] ~ label {
  padding-left: 10px;
  cursor: pointer;
}

.form-wizard .form-wizard-header {
  text-align: center;
 
}

.form-wizard .form-wizard-next-btn,
.form-wizard .form-wizard-previous-btn,
.form-wizard .form-wizard-submit {
  background-color: #66bb6a; /* #43a39f color */
  color: #ffffff;
  display: inline-block;
  min-width: 120px;
  padding: 10px;
  text-align: center;
}

.form-wizard .form-wizard-next-btn:hover,
.form-wizard .form-wizard-next-btn:focus,
.form-wizard .form-wizard-previous-btn:hover,
.form-wizard .form-wizard-previous-btn:focus,
.form-wizard .form-wizard-submit:hover,
.form-wizard .form-wizard-submit:focus {
  color: #ffffff;
  opacity: 0.6;
  text-decoration: none;
}

.form-wizard .wizard-fieldset {
  display: none;
}

.form-wizard .wizard-fieldset.show {
  display: block;
  background: none !important;
}

.form-wizard .wizard-form-error {
  display: none;
  background-color: #d70b0b;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 2px;
  width: 100%;
}

.form-wizard .form-wizard-previous-btn {
  background-color: #66bb6a; /* #43a39f color */
}

.form-wizard .form-control {
  font-weight: 500;
  color: black;
  height: auto !important;
  padding: 15px;
  color: #888888;
  background-color: #f1f1f1;
  border: none;
}

.form-wizard .form-control:focus {
  background-color: #f1f1f1; /* Keep the original background color when focused */
  box-shadow: none;
}

.form-wizard .form-group {
  position: relative;
  margin: 25px 0;
}

.form-wizard .wizard-form-text-label {
  position: absolute;
  left: 10px;
  top: 16px;
  transition: 0.2s linear all;
  background-color: transparent;
}

.form-wizard .focus-input .wizard-form-text-label {
  color: black;
  top: -18px;
  transition: 0.2s linear all;
  font-size: 12px;
  background-color: transparent;
}

.form-wizard .form-wizard-steps {
  margin: 30px 0;
}

.form-wizard .form-wizard-steps li {
  float: left;
  margin-left: 61px;
  position: relative;
}

.form-wizard .form-wizard-steps li::after {
  background-color: #f3f3f3;
  content: "";
  height: 5px;
  left: 0;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  border-bottom: 1px solid #dddddd;
  border-top: 1px solid #dddddd;
}

.form-wizard .form-wizard-steps li span {
  background-color: #dddddd;
  border-radius: 50%;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  position: relative;
  text-align: center;
  width: 40px;
  z-index: 1;
}

.form-wizard .form-wizard-steps li:last-child::after {
  width: 50%;
}

.form-wizard .form-wizard-steps li.active span,
.form-wizard .form-wizard-steps li.activated span {
  background-color: #66bb6a; /* #43a39f color */
  color: #ffffff;
}

.form-wizard .form-wizard-steps li.active::after,
.form-wizard .form-wizard-steps li.activated::after {
  background-color: #66bb6a; /* #43a39f color */
  left: 50%;
  width: 50%;
  border-color: #66bb6a; /* #43a39f color */
}

.form-wizard .form-wizard-steps li.activated::after {
  width: 100%;
  border-color: #66bb6a; /* #43a39f color */
}

.form-wizard .form-wizard-steps li:last-child::after {
  left: 0;
}

.form-wizard .wizard-password-eye {
  position: absolute;
  right: 32px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

@media screen and (max-width: 767px) {
  .wizard-content-left {
    height: auto;
  }
  .form-wizard{
    margin-top: 10px !important;
  }
}

.next {
  float: right;
}

.form-wizard .form-wizard-fieldset-step2 {
  display: none;
}

.form-wizard .form-wizard-fieldset-step2.show {
  display: block;
  background: none !important;
}

.form-wizard .form-wizard-fieldset-step3 {
  display: none;
}

.form-wizard .form-wizard-fieldset-step3.show {
  display: block;
  background: none !important;
}
