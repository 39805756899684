.trainersAddSection {
  padding: 60px 0;
}
.trainersAddSection h1 {
  font-size: 28px;
  font-family: "Unbounded", sans-serif;
  color: #444444;
  font-weight: 700;
}
.trainersAddSection p {
  color: #e0a721;
  font-size: 16px;
  font-weight: 400;
}
.trainersAddSection .trainersList {
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.trainersAddSection .trainersList li {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
  color: #444444;
  font-weight: 500;
  margin: 0 6px;
  font-family: "Poppins", sans-serif;
  border: 1px solid #43a39f;
}
.trainersAddSection .trainersList li.active {
  background: #43a39f;
  color: #fff;
}
.trainersAddForm {
  background: #fff !important;
  transition: all 0.5s ease;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.1);
  border-radius: 20px !important;
  padding: 20px 15px !important;
  max-width: 776px;
  width: 100%;
  margin: 0 auto;
}

.form-control::placeholder {
  font-size: 16px !important;
}
