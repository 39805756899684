/*   view more section  starts here*/

.ViewMoreForm {
  background: #fff !important;
  transition: all 0.5s ease;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.1);
  border-radius: 20px !important;
  padding: 15px 35px 0px !important;
  max-width: 776px;
  width: 100%;
  margin: 40px auto;
  border: none;
}
form .card-body {
  padding: 0;
}

.ViewMoreForm .row {
  border-bottom: 1px solid #43a3a07e !important ;
}
.ViewMoreForm .row:last-child {
  border-bottom: none !important;
}

.ViewMoreForm h6 {
  font-size: 14px;
  font-weight: 700;
  color: #444444;
  margin: 0;
}
.ViewMoreForm p {
  font-size: 12px;
  font-weight: 400;
  color: #444444 !important;
  letter-spacing: 0.5px;
  margin: 0;
}
.ViewMoreForm .link a {
  color: #43a39f !important;
  font-style: italic;
}
/* Demos page  view more section  ends here*/

@media screen and (max-width: 575.98px) {
  .form-floating .form-control {
    padding: 6px 10px !important;
    min-height: 44px !important;
    font-size: 12px !important;
  }
  .form-floating .css-b62m3t-container.form-control,
  .form-floating input.form-control {
    max-height: 44px !important;
  }
}
