button:focus,
button:focus-visible {
  box-shadow: none !important;
  outline: none !important;
}
.btn.filterToggle {
  border: 1px solid #111;
  color: #111;
  width: 34px;
  min-width: 34px;
  height: 34px;
  border-radius: 50%;
}
.btn.filterToggle svg {
  font-size: 26px;
}
.filter-chip {
  font-size: 14px;
  text-transform: uppercase;
  font-weight: 500;
}
.sidebar {
  width: 320px;
  padding: 16px 12px;
  box-shadow: rgba(0, 0, 0, 0.2) 5px 0px 0px -6px;
  border-right: 1px solid #ddd;
}
.sidebar .clear-filters-button button {
  font-size: 14px;
  width: fit-content;
}
.sidebar .inputGroup {
  border-radius: 6px;
  overflow: hidden;
  padding: 4px;
  border: 1px solid #7b7b7b58;
}
.sidebar .inputGroup .form-control {
  background: transparent;
  font-weight: normal;
  border: none;
  color: #111;
  min-height: 38px !important;
  height: 38px !important;
  font-size: 14px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.custom-checkbox .form-check-input[type="checkbox"] {
  position: absolute;
  opacity: 0;
}
.newsletter-item .form-check-label {
  width: 100%;
}
.custom-checkbox .form-check-label {
  position: relative;
  padding-left: 26px;
  font-size: 12px;
  text-transform: capitalize;
}

.custom-checkbox .form-check-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1.5px solid #888;
  border-radius: 1px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
}

.custom-checkbox .form-check-input:checked + .form-check-label::before {
  content: "✔";
  border: 2px solid #42a49f;
  background-color: #42a49f;
}

.sidebar .inputGroup .form-control:focus {
  border: none;
}
.sidebar .inputGroup .form-control::placeholder {
  font-weight: 400 !important;
  color: #2444;
}
.sidebar .input-group-text {
  border: none !important;
  border-radius: 50% !important;
  background: #42a49f !important;
  color: #fff !important;
  height: 38px;
  width: 38px;
  justify-content: center;
}

.sidebar .accordion .accordion-button:not(.collapsed) {
  background-color: #fff;
  border-color: #42a49f;
  color: #42a49f;
}
.sidebar .accordion-button:focus {
  border-color: #ddd;
}
.sidebar .accordion-button {
  text-transform: capitalize;
  font-size: 12px;
  padding: 12px 8px;
  border-bottom: 1px solid #ccc;
  border-radius: 0;
}
.sidebar .accordion-button::after {
  border-radius: 50%;
  padding: 5px;
  background-size: 0.8rem;
  width: 1.5rem;
  height: 1.5rem;
  background-position: center;
}
.sidebar .accordion-item {
  border: none;
  background: transparent;
}
.side-bar-close-btn .btn {
  font-size: 18px;
  width: 32px;
  height: 32px;
  color: red;
  border-radius: 50%;
  border: 1px solid red;
}
@media screen and (max-width: 1199.98px) {
  .sidebar {
    width: 290px;
    padding: 16px 10px;
  }
}
@media screen and (max-width: 991.98px) {
  .sidebar {
    position: fixed;
    top: 0;
    width: 0;
    max-width: 0;
    right: -100%;
    overflow: hidden;
    background-color: #fff;
    padding: 30px;
    height: 100vh;
    overflow-y: scroll;
    z-index: 999999;
    transition: all 0.35s ease-in-out;
  }

  .sidebar + div.overlay {
    opacity: 0;
    visibility: hidden;
    content: "";
    height: 100vh;
    width: 100vw;
    position: fixed;
    top: 0;
    right: 0;
    background-color: #3a3a3a76;
    transition: all 0.35s ease-in-out;
    z-index: 99999;
  }
  .sidebar.view + div.overlay {
    opacity: 1;
    visibility: visible;
  }
  .sidebar.view {
    transition: all 0.35s ease-in-out;
    right: 0%;
    width: 100%;
    max-width: 540px;
  }
}
@media screen and (max-width: 767.98px) {
  .custom-checkbox .form-check-label,
  .sidebar .clear-filters-button button {
    font-size: 12px;
  }
  .sidebar {
    padding: 18px;
  }
  .sidebar .inputGroup {
    padding: 2px;
  }
  .sidebar .input-group-text {
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
  }
  .sidebar .inputGroup .form-control {
    min-height: 34px !important;
    height: 34px !important;
  }
  .sidebar .inputGroup .form-control::placeholder {
    font-size: 14px !important;
  }
}
@media screen and (max-width: 575.98px) {
  .sidebar {
    padding: 12px;
  }
  .side-bar-close-btn .btn {
    font-size: 16px;
    width: 24px;
    height: 24px;
  }
  .sidebar .input-group-text {
    height: 28px;
    width: 28px;
    min-height: 28px;
    min-width: 28px;
    padding: 0;
  }
  .sidebar .input-group-text svg {
    font-size: 12px;
  }
  .sidebar .inputGroup .form-control {
    min-height: 28px !important;
    height: 28px !important;
  }
  .sidebar .accordion-button {
    padding: 8px;
  }
  .sidebar .accordion-body {
    padding: 8px 12px;
  }
  .custom-checkbox .form-check-label::before {
    width: 14px;
    height: 14px;
  }
  .custom-checkbox .form-check-label {
    padding-left: 20px;
  }
  .btn.filterToggle {
    width: 28px;
    min-width: 28px;
    height: 28px;
  }
  .btn.filterToggle svg {
    font-size: 18px;
  }
}
