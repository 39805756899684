.uploaded-thumb {
  min-width: 180px;
  max-width: 180px !important;
  min-height: 180px;
  max-height: 180px;
}
.uploaded-file {
  min-width: 100px;
  max-width: 100px !important;
  min-height: 100px;
  max-height: 100px;
}
.uploaded-thumb-edit {
  height: 180px !important;
  background-color: #dddddd !important;
}
.uploaded-video-edit {
  height: 240px !important;
}
.text-field-name {
  width: 90%;
  font-size: 12px;
  white-space: normal;
  height: 14px;
  text-align: center;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
}

.upload-file {
  min-width: 180px;
  max-width: 180px !important;
  min-height: 180px;
  max-height: 180px;
}
.upload-file.initial {
  border-style: dashed;
}

@media screen and (max-width: 575.98px) {
  .uploaded-thumb {
    min-width: 100%;
    max-width: 100% !important;
  }
}
