.parentLoader {
  height: 220px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loader {
  width: 8px;
  height: 40px;
  border-radius: 4px;
  display: block;
  margin: 20px auto;
  position: relative;
  background: currentColor;
  color: #42a49f;
  box-sizing: border-box;
  animation: animloader 0.4s 0.4s linear infinite alternate;
}

.loader::after,
.loader::before {
  content: "";
  width: 8px;
  height: 40px;
  border-radius: 4px;
  background: currentColor;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 20px;
  box-sizing: border-box;
  animation: animloader 0.4s 0.55s linear infinite alternate;
}
.loader::before {
  left: -20px;
  animation-delay: 0s;
}

@keyframes animloader {
  0% {
    height: 48px;
  }
  100% {
    height: 4px;
  }
}
@media (max-width: 575.98px) {
  .loader,
  .loader::after,
  .loader::before {
    width: 6px;
    height: 30px;
    height: 30px;
  }
  .loader::before {
    left: -15px;
  }
  .loader::after {
    left: 15px;
  }
  @keyframes animloader {
    0% {
      height: 38px;
    }
    100% {
      height: 4px;
    }
  }
}
