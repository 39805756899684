.reviewCard.card {
  border: 1px solid #eee;
  transform: all 0.5s ease;
}
.reviewCard.card:hover {
  box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;
}
.reviewCard {
  padding-bottom: 16px;
  font-size: 14px;
  margin-right: 8px;
}

.reviewCard .card-image {
  border-radius: 20px;
  height: 30vh;
}
.reviewCard .card-image img {
  height: 100%;
  object-position: center;
  border-radius: inherit;
  object-fit: cover;
}
.reviewCard .stars svg {
  color: rgb(255, 217, 0);
  font-size: 18px;
}
@media screen and (max-width: 767.98px) {
  .reviewCard .card-image {
    border-radius: 20px;
    height: 26vh;
}
}
@media screen and (max-width: 575.98px) {
  .reviewCard .card-image {
    border-radius: 20px;
    height: 20vh;
  }
  .reviewCard .card-title{
    font-size: 16px;
  }
  .reviewCard .card-text{
    font-size: 12px;
    margin-bottom: 10px;
  }
}
