body {
  margin: 0;
  padding: 0;
}
.ContactUsPage {
  padding: 60px 0;
}
.ContactUsForm {
  background: #fff !important;
  transition: all 0.5s ease;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.1);
  border-radius: 20px !important;
  max-width: 920px;
  width: 100%;
  margin: 0 auto;
}
.ContactUsPage .row {
  margin: auto 0 !important;
}
.ContactUsForm h1 {
  font-size: 28px;
  font-family: "Unbounded", sans-serif;
  color: #e0a721;
  font-weight: 700;
}
.ContactBottom {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ContactSubmitBtn {
  background-color: #43a39f;
  color: #fff;
  border-radius: 4px;
  border: 2px solid transparent;
  width: 200px;
}
.ContactSubmitBtn:hover {
  background-color: #fff;
  color: #43a39f;
  border: 2px solid #43a39f;
}
.social-media-links a {
  margin-left: 24px;
  width: 34px;
  height: 34px;
  font-size: 18px;
  border-radius: 50%;
  border: 1px solid #43a39f;
  color: #43a39f;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  transition: all 0.35s;
}
.social-media-links a:hover {
  background-color: #43a39f;
  color: #fff;
}
.social-media-links a:nth-child(1) {
  margin-left: unset !important;
}
.ContactBottom .contactAddress {
  font-size: 10px;
  font-weight: 500;
  color: #4444449a !important;
}

@media screen and (max-width: 991.98px) {
  .ContactUsForm {
    max-width: 90% !important;
    padding: 25px !important;
  }
}

@media screen and (max-width: 575.98px) {
  .ContactUsForm {
    max-width: 100% !important;
    padding: 14px 4px !important;
  }
  .ContactUsForm h1 {
    font-size: 20px !important;
    margin: 10px 0px 24px 0 !important;
  }
  .social-media-links a {
    margin-left: 10px !important;
  }

  .ContactBottom .contactAddress {
    font-size: 8px !important;
  }
  .ContactBottom {
    margin: 10px 0 !important;
  }
  .ContactSubmitBtn {
    padding: 6px;
    font-size: 14px;
  }
  .ContactUsForm p{
    font-size: 12px;
    margin-bottom: 0;
  }
}
