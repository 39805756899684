.about-sec .about-headings {
  position: relative;
  font-size: 28px;
  color: #000;
  margin-bottom: 28px;
}
.about-sec h4.about-headings::after {
  content: "";
  width: 8px;
  height: 8px;
  background-color: #43a39f;
  position: absolute;
  border-radius: 40px;
  top: 100%;
  left: 0;
}
.about-sec h4.about-headings::before {
  content: "";
  width: 36px;
  height: 5px;
  background-color: #43a39f;
  position: absolute;
  border-radius: 40px;
  top: calc(100% + 1.5px);
  left: 12px;
}
.about-sec .lead {
  font-size: 16px;
  color: #333;
  line-height: 1.5;
}
.about-sec .about-img {
  border-radius: 20px;
  object-fit: cover;
}
.about-sec .about-outlined-btn {
  background-color: transparent;
  color: #43a39f;
  border: 2px solid #43a39f;
  transition: all 0.35s;
  border-radius: 0 !important;
  padding: 8px 26px 7px 26px;
}

.about-sec .about-outlined-btn:hover {
  border: 2px solid #43a39f;
  background-color: #43a39f;
  color: #fff;
}
.about-sec .about-solid-btn {
  color: #fff;
  border-radius: 0 !important;
  padding: 8px 26px 7px 26px;
  background-color: #43a39f;
  border: 2px solid transparent;
  transition: all 0.35s;
}

.about-sec .about-solid-btn:hover {
  border: 2px solid #43a39f;
  background-color: transparent;
  color: #43a39f;
}
@media screen and (max-width: 767.98px) {
  .about-sec .about-headings {
    font-size: 24px;
    margin-bottom: 24px;
  }
  .about-sec .lead {
    font-size: 14px;

    margin-bottom: 0;
  }
  .about-sec .about-solid-btn {
    padding: 6px 20px 5px 20px;
    font-size: 14px;
  }
  .about-sec .about-outlined-btn {
    padding: 6px 22px 5px 22px;
    font-size: 14px;
  }
}
@media screen and (max-width: 575.98px) {
  .about-sec .about-headings {
    font-size: 18px;
    margin-bottom: 18px;
  }
  .about-sec .lead {
    font-size: 12px;
    margin-bottom: 0;
  }
  .about-sec .about-solid-btn,
  .about-sec .about-outlined-btn {
    padding: 6px 22px 5px 22px;
    font-size: 12px;
  }
}
