.profileCard.card {
  border: none !important;
  min-height: 100%;
  position: relative;
  padding: 0;
  border-radius: 4px;
}
.profileCard.card .card-body {
  padding-top: 80px;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.2);
}
.profileCard.card .contUser {
  padding: 0 16px;
  margin-bottom: -76px;
  position: relative;
  z-index: 99;
}
.profileCard.card a.entity-share-btn {
  height: 28px;
  width: 28px;
  position: absolute;
  top: 52px;
  right: 8px;
}
.profileCard.card .userImage {
  display: block;
  width: 120px;
  min-width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4px solid#eee;
  background-color: #fff;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.4);
}
.profileCard.card .userImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}
.profileCard.card .industry {
  background-color: rgb(255, 157, 0);
  color: #fff;
  border-radius: 6px;
  width: fit-content;
  margin: 18px auto 0 auto;
  padding: 1px 8px;
  font-weight: 500;
  text-align: center;
  display: block;
}

.profileCard.card .username {
  font-size: 18px;
  color: #43a39f;
  font-weight: bold;
  text-decoration: none;
  line-height: normal;
  display: block;
}
.profileCard.card .specialization {
  font-weight: bold;
}
.profileCard.card p.info {
  font-size: 14px;
  line-height: 1.4;
}
.profileCard.card .stars .star {
  color: rgb(255, 157, 0);
}
.profileCard.card .stars p {
  font-size: 13px;
}

.profileCard.card .actionBtn {
  width: 100%;
  font-size: 12px;
  font-weight: 500;
  border: 2px solid transparent;
  background-color: #43a39f;
  text-align: center;
  border-radius: 4px;
  padding: 7.5px 16px 7px 16px;
  color: #fff;
  transition: all 0.35s;
}
.profileCard.card .actionBtn:hover {
  color: #43a39f;
  background: transparent;
  border: 2px solid #43a39f;
}
.profileCard.card .actionDltBtn {
  font-size: 12px;
  padding: 7.5px 16px 7px 16px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  background-color: #e02128;
  color: #fff;
  border: 2px solid transparent;
  transition: all 0.35s;
}
.profileCard.card .actionDltBtn:hover {
  border: 2px solid #e02128;
  color: #e02128;
  background-color: transparent;
}

@media screen and (max-width: 1199.98px) {
  .profileCard.card .actionDltBtn,
  .profileCard.card .actionBtn,
  .profileCard.card .username {
    font-size: 14px;
  }
}
@media screen and (max-width: 767.98px) {
  .profileCard.card .contUser {
    margin-bottom: -56px !important;
  }
  .profileCard.card a.entity-share-btn.actionBtn.btn {
    font-size: 10px;
    top: 72px;
  }
}
@media screen and (max-width: 575.98px) {
  .profileCard.card a.entity-share-btn.actionBtn.btn {
    padding: 0 !important;
  }
  .profileCard.card .specialization,
  .profileCard.card .stars p {
    font-size: 12px;
    margin-bottom: 0 !important;
  }
  .rateValue {
    padding-left: 5px;
    font-size: 12px;
  }
  .profileCard.card .userImage {
    width: 100px;
    min-width: 100px;
    height: 100px;
  }

  .profileCard.card .actionDltBtn,
  .profileCard.card .actionBtn {
    width: 100%;
    font-size: 12px !important;
    padding: 6px 12px !important;
  }
  .profileCard.card .industry {
    border-radius: 4px;
    font-size: 12px;
    margin: 12px auto 0 auto;
    padding: 2px 8px;
  }
}
