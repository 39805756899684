.payheader {
  background-color: #f1f6f5;
  font-size: 20px;
  padding: 10px;
}
.form-control.mid-field {
  border-radius: 0 !important;
  border-left: none;
  border-right: none;
}
