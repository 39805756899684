.profile .image-wrap {
  border: 3px dashed #777;
}
.profile .image-wrap .placehold-text {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.534);
  color: #f7f7f7;
  font-size: 0.7rem;
  text-align: center;
  padding: 8px 0;
}
.profile .profile-upload {
  height: 10rem;
  width: 10rem;
}
