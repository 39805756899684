.reviewCard.card {
  border: none;
  transform: all 0.5s ease;
  min-height: 100%;
  box-shadow: 0 1px 20px 0 rgba(69, 90, 100, 0.2);
  transition: all 0.25s;
}

.reviewCard.card {
  padding-bottom: 16px;
  font-size: 14px;
  margin-right: 8px;
}

.reviewCard.card .card-image {
  border-radius: 8px;
  height: 22vh;
}
.reviewCard.card .card-image img {
  height: 100%;
  object-position: center;
  border-radius: inherit;
  object-fit: contain;
}
.reviewCard.card .stars {
  justify-content: center;
}
.actionBtnMore.btn,
.reviewCard.card .actionBtn {
  width: 100%;
  font-size: 14px;
  font-weight: 500;
  border: 2px solid transparent;
  background-color: #43a39f;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  transition: all 0.35s;
}
.actionBtnMore.btn:hover,
.reviewCard.card .actionBtn:hover {
  color: #43a39f;
  background: transparent;
  border: 2px solid #43a39f;
}
.reviewCard.card .actionDltBtn {
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 4px;
  background-color: #e02128;
  color: #fff;
  border: 2px solid transparent;
  transition: all 0.35s;
}
.reviewCard.card .actionDltBtn:hover {
  border: 2px solid #e02128;
  color: #e02128;
  background-color: transparent;
}

@media screen and (max-width: 767.98px) {
  .reviewCard.card .card-image {
    border-radius: 20px;
    height: 20vh;
  }
  .filter-chip {
    font-size: 12px;
  }
}
@media screen and (max-width: 575.98px) {
  .reviewCard.card .card-image {
    border-radius: 18px;
  }
  .reviewCard.card .card-title {
    font-size: 16px;
  }
  .reviewCard.card .card-text {
    font-size: 12px;
    margin-bottom: 10px;
  }
  .reviewCard.card .actionDltBtn,
  .reviewCard.card .actionBtn {
    width: 100%;
    font-size: 12px !important;
    padding: 6px 12px !important;
  }
  .filter-chip {
    font-size: 10px;
  }
}
