.instituelist th {
  background: #363639 !important;
  color: #fff !important;
}
.instituelist th svg,
.instituelist th input {
  color: #fff !important;
}
.boxstyle {
  box-shadow: 3px 1px 6px 0px #eee;
  padding: 10px;
  background-color: #fff;
}
.input2 {
  font-weight: 700 !important;
  background-color: white !important;
  opacity: 1 !important;
  color: #43a39f !important;
}

.float-end a i {
  color: white;
}
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  background-color: black;
  color: white !important;
}

.card-icons {
  display: flex;
  align-items: center;
}
.box1 {
  display: flex;
  width: 87%;
  display: flex;
}

.card-footer {
  margin-top: 10px;
}

.card-action {
  margin-right: 10px;
  color: #000000;
  font-size: 18px;
}

.wizard-fieldset {
  padding: 15px;
  border-radius: 5px;
}
