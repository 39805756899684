.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    border: 15px solid #1f2e3f;
    box-shadow: 5px 3px 4px 0px #1f2e3f;

  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  .videoContent{
    margin-top: 10px;;
  }
.videocontainer{
    min-height: 500px;
}
.videodesc{
    background-color: #222e3e;
    color: #fff;
    padding-left: 15px;
    box-shadow: 4px 2px 3px 1px #222e3e;
    padding-bottom: 15px;

}
.videodesc p{
    margin-bottom: 5px;
}
h3.vidheader {
    background: #222;
    color: #fff;
    padding: 10px;
    font-size: 20px;
}
